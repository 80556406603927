import React, { useEffect } from 'react'
import { Accordion, Border, Box, Button, Checkbox, DropDown, Grid, Icon, Image, Layer, RadioButtons, ResponsiveWrapper, PhoneScreen, TabletScreen, LaptopScreen, DesktopScreen, Text, TextArea, TextField, Underline, Meta } from '../Elements'
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 } from '../Components'
import { FiMenu } from 'react-icons/fi'
import { c_businessName, c_routes } from '../../Constants'
import { useLocation, useNavigate } from 'react-router'
import Navigation1 from './UTILITIES/Navigation1'
import Footer1 from './UTILITIES/Footer1'
// 
import img1 from '../../PHOTOS/tuning.jpg'
import img2 from '../../PHOTOS/pitch-raise2.jpg'
import img3 from '../../PHOTOS/cleaning2.jpg'
import img4 from '../../PHOTOS/repair.jpg'

export default function Services1() {
    const navigate = useNavigate()
    const location = useLocation()
    const currentPath = location.pathname

    // function openNav() {
    //     if (window.innerWidth < 600) {
    //         document.querySelector(".nav-body").style.width = "100vw";
    //     } else if (window.innerWidth < 800) {
    //         document.querySelector(".nav-body").style.width = "50vw";
    //     } else if (window.innerWidth < 1000) {
    //         document.querySelector(".nav-body").style.width = "40vw";
    //     } else if (window.innerWidth < 1200) {
    //         document.querySelector(".nav-body").style.width = "35vw";
    //     } else {
    //         document.querySelector(".nav-body").style.width = "20vw";
    //     }
    // }

    // function closeNav() {
    //     document.querySelector(".nav-body").style.width = "0";
    // }

    const thing1 = "Here are our basic services. More details and options can be found on our BOOK A TUNING page."

    const services = [
        {
            Name: "INITIAL TUNING NEW CLIENTS $225",
            Desc: "Includes tuning, full evaluation, and condition report.",
            Image: img1
        },
        {
            Name: "TUNING/CLEANING BUNDLE $575",
            Desc: "This bundle includes a thorough clean of the piano inside and out, tuning, full evaluation, and condition report.",
            Image: img4
        },
        {
            Name: "REPAIRS & RESTORATION",
            Desc: "Sticking keys? Something broken? Some are easy fixes and others take more time and may require parts. We'll take a look at the problem and let you know if there will be additional costs or future visits to complete the repair.",
            Image: img3
        },
        {
            Name: "REGULATION/VOICING",
            Desc: "Regulation and voicing are adjustments that help you play smoothly and sweeten your tone. It's recommended to be done at least every 5-10 years. Please contact us for an estimate.",
            Image: img2
        },
    ]

    useEffect(() => {
        // closeNav()
    }, [])

    return (
        <div className='roboto'>
            <Meta route={c_routes.reduce((found, route) => { if (route.Route === currentPath.replace("/","")) { return route; } return found; }, null)} />
            <Navigation1 />
        
            <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                <ResponsiveWrapper>
                <PhoneScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="1em" classes="">
                        <Heading1 text={`Pricing`} />
                        <Text text={`${thing1}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes="" />
                    </Box>
                
                </PhoneScreen>
                <TabletScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="1em" classes="">
                        <Heading1 text={`Pricing`} />
                        <Text text={`${thing1}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes="" />
                    </Box>
                
                </TabletScreen>
                <LaptopScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="0" paddingH="4vw" classes="">
                        <Heading1 text={`Pricing`} />
                        <Text text={`${thing1}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes="" />
                    </Box>
                </LaptopScreen>
                <DesktopScreen>
                <Box width="" height="" radius="" backgroundColor="" paddingV="0" paddingH="13vw" classes="">
                    <Heading1 text={`Pricing`} />
                    <Text text={`${thing1}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes="" />
                </Box>
                
                </DesktopScreen>
                </ResponsiveWrapper>
                
                

                <Box width="" height="4vh" radius="" paddingV="" paddingH="" classes="">

                </Box>

                <ResponsiveWrapper>
                <PhoneScreen>
                    <Grid orientation="column" template="1fr" gap="1.2em" classes="">
                    {
                        services.map((service, i) => {
                            return (
                                <Box width="" height="" radius="" paddingV="0" paddingH="1em" classes="">
                                    <Box width="" height="40vh" radius="" paddingV="" paddingH="" classes="">
                                        <Image src={service.Image} alt="" radius="" classes="fill" />
                                    </Box>
                                    <Text text={`${service.Name}`} fontSize="1.6em" weight="400" color="" spacing="" lineHeight="" classes="" />
                                    <Text text={`${service.Desc}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes="" />
                                </Box>
                            )
                        })
                    }
                </Grid>
                    
                
                </PhoneScreen>
                <TabletScreen>
                <Grid orientation="column" template="1fr 1fr" gap="1.2em" classes="">
                    {
                        services.map((service, i) => {
                            return (
                                <Box width="" height="" radius="" paddingV="0" paddingH="1em" classes="">
                                    <Box width="" height="40vh" radius="" paddingV="" paddingH="" classes="">
                                        <Image src={service.Image} alt="" radius="" classes="fill" />
                                    </Box>
                                    <Text text={`${service.Name}`} fontSize="1.6em" weight="400" color="" spacing="" lineHeight="" classes="" />
                                    <Text text={`${service.Desc}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes="" />
                                </Box>
                            )
                        })
                    }
                </Grid>
                </TabletScreen>
                <LaptopScreen>
                <Grid orientation="column" template="1fr 1fr" gap="1.2em" classes="">
                    {
                        services.map((service, i) => {
                            return (
                                <Box width="" height="" radius="" paddingV="0" paddingH="5vw" classes="">
                                    <Box width="" height="40vh" radius="" paddingV="" paddingH="" classes="">
                                        <Image src={service.Image} alt="" radius="" classes="fill" />
                                    </Box>
                                    <Text text={`${service.Name}`} fontSize="1.6em" weight="400" color="" spacing="" lineHeight="" classes="" />
                                    <Text text={`${service.Desc}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes="" />
                                </Box>
                            )
                        })
                    }
                </Grid>
                </LaptopScreen>
                <DesktopScreen>
                <Box width="" height="" radius="" paddingV="4vh" paddingH="13vw" classes="center">
                <Grid orientation="column" template="1fr 1fr" gap="1.5em" classes="">
                    {
                        services.map((service, i) => {
                            return (
                                <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                    <Box width="" height="40vh" radius="" paddingV="" paddingH="" classes="">
                                        <Image src={service.Image} alt="" radius="" classes="fill" />
                                    </Box>
                                    <Text text={`${service.Name}`} fontSize="1.6em" weight="400" color="" spacing="" lineHeight="" classes="" />
                                    <Text text={`${service.Desc}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes="" />
                                </Box>
                            )
                        })
                    }
                </Grid>
                </Box>
                </DesktopScreen>
                </ResponsiveWrapper>
            </Box>

            <Footer1 />
        </div>
    )
}